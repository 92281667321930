import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import IOL from "../pages/IOL";
import CYB from "../pages/CYB";


const RoutesPath = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={< Home />} />
                <Route path="/iol" element={< IOL />} />
                <Route path="/cyb" element={< CYB />} />
            </Routes>
        </>
    );
};
export default RoutesPath;