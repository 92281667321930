import React from 'react';
import './App.css';
import { BrowserRouter } from "react-router-dom";
import RoutesPath from './routes';
import { AuthProvider } from './context/AuthContext';

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <RoutesPath />
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
