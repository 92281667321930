import axios from 'axios';
import { ethers } from 'ethers';
import { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import Login from '../components/Login';
import { Link } from 'react-router-dom';

interface BotSettings {
    max_price_impact_wIOTA_USDT: number;
    slippage_wIOTA_USDT: number;
    slippage_wIOTA_IOL: number;
    value_of_growth_to_sell: [number, number];
    IOL_price_threshold_percent: [number, number];
    minIOLprice: number;
    customRPC: string;  // Add this line
}

interface SwapData {
    blockNumber: number;
    percentPriceBias: string;
    percentOfBiasToSwap: string;
    amountIOLswapped: string;
    amountIOTAswapped: string;
    usdtReceived: string;
    txIOLsell: string;
    txIOTAsell: string;
    timestamp: string;
}

interface Balances {
    wIOTA: string;
    IOL: string;
    USDT: string;
    native: string;
    treasuryUSDTbalance: string;
}

interface Log {
    message: string;
    timestamp: string;
}

interface Status {
    isRunning: boolean;
}

const setupAxiosInterceptors = (logoutCallback: () => void) => {
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response?.status === 401) {
                logoutCallback();
            }
            return Promise.reject(error);
        }
    );
};
const IOL = () => {
    const { isLoggedIn, setIsLoggedIn } = useAuth();
    const [status, setStatus] = useState<string>('');
    const [settings, setSettings] = useState<BotSettings | null>(null);
    const [swapHistory, setSwapHistory] = useState<SwapData[]>([]);
    const [pollingInterval, setPollingInterval] = useState<NodeJS.Timeout | null>(null);
    const [logs, setLogs] = useState<Log[]>([]);
    const [balances, setBalances] = useState<Balances | null>(null);
    const [isLogsVisible, setIsLogsVisible] = useState(false);
    const [isSwapsVisible, setIsSwapsVisible] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [botStatus, setBotStatus] = useState<Status>({ isRunning: false });

    // Move setupPolling inside the component to access component functions
    const setupPolling = () => {
        // 1. Data polling interval (every 10 seconds)
        const dataInterval = setInterval(async () => {
            try {
                await fetchSwapHistory();
                await fetchLogs();
                await fetchBalances();
                await fetchStatus();
            } catch (error) {
                console.error('Data polling error:', error);
            }
        }, 10000);

        // 2. Token verification interval (every 30 seconds)
        const tokenInterval = setInterval(async () => {
            try {
                await verifyToken(localStorage.getItem('token') || '');
            } catch (error) {
                console.error('Token verification error:', error);
                logout();
            }
        }, 30000);

        setPollingInterval(dataInterval);

        return { dataInterval, tokenInterval };
    };

    useEffect(() => {
        // Setup axios interceptor when component mounts
        setupAxiosInterceptors(logout);

        const storedToken = localStorage.getItem('token');
        if (storedToken && isLoggedIn) {
            const initializeApp = async () => {
                try {

                    await verifyToken(storedToken);

                    await Promise.all([
                        fetchCurrentSettings(),
                        fetchSwapHistory(),
                        fetchLogs(),
                        fetchBalances(),
                        fetchStatus()
                    ]);
                    setStatus('Successfully fetched initial data');

                    const intervals = setupPolling();

                    // Clean up function
                    return () => {
                        clearInterval(intervals.dataInterval);
                        clearInterval(intervals.tokenInterval);
                    };
                } catch (error) {
                    console.error('Initialization error:', error);
                    logout();
                }
            };

            initializeApp();
        }

        // Clean up when component unmounts
        return () => {
            if (pollingInterval) {
                clearInterval(pollingInterval);
            }
        };
    }, [isLoggedIn]); // Add isLoggedIn to dependency array

    const logout = () => {
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        setSettings(null);
        setStatus('');
        if (pollingInterval) {
            clearInterval(pollingInterval);
            setPollingInterval(null);
        }
    };

    const controlBot = async (action: 'start' | 'stop') => {
        try {
            const response = await axios.post(`http://${process.env.REACT_APP_API_ADDRESS}/${action}`, {}, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setStatus(response.data);
        } catch (error) {
            console.error('Error:', error);
            setStatus(`Error: ${(error as Error).message}`);
        }
    };

    const updateSettings = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const newSettings = {
            max_price_impact_wIOTA_USDT: Number(formData.get('max_price_impact_wIOTA_USDT')),
            slippage_wIOTA_USDT: Number(formData.get('slippage_wIOTA_USDT')),
            slippage_wIOTA_IOL: Number(formData.get('slippage_wIOTA_IOL')),
            value_of_growth_to_sell: [
                Number(formData.get('value_of_growth_to_sell_min')),
                Number(formData.get('value_of_growth_to_sell_max'))
            ],
            IOL_price_threshold_percent: [
                Number(formData.get('IOL_price_threshold_percent_min')),
                Number(formData.get('IOL_price_threshold_percent_max'))
            ],
            minIOLprice: ethers.parseEther(formData.get('minIOLpriceInUSDT')?.toString() || '0').toString(),
            customRPC: formData.get('customRPC') || ''
        };

        try {
            const response = await axios.post(`http://${process.env.REACT_APP_API_ADDRESS}/settings`, newSettings, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setStatus(response.data);
            fetchCurrentSettings();
        } catch (error) {
            console.error('Error:', error);
            setStatus(`Error: ${(error as Error).message}`);
        }
    };

    const fetchCurrentSettings = async () => {
        try {
            const response = await axios.get<BotSettings>(`http://${process.env.REACT_APP_API_ADDRESS}/settings`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setSettings(response.data);
        } catch (error) {
            console.error('Error fetching settings:', error);
            setStatus(`Error fetching settings: ${(error as Error).message}`);
        }
    };

    const verifyToken = async (token: string) => {
        try {
            const response = await axios.get(`http://${process.env.REACT_APP_API_ADDRESS}/verify-token`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            if (response.data.valid) {
                setIsLoggedIn(true);
                fetchCurrentSettings();
            } else {
                logout();
            }
        } catch (error) {
            console.error('Token verification error:', error);
            logout();
        }
    };

    const fetchSwapHistory = async () => {
        try {
            const response = await axios.get<SwapData[]>(`http://${process.env.REACT_APP_API_ADDRESS}/swaps`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            // Sort the swaps by block number in descending order (newest first)
            const sortedSwaps = [...response.data].sort((a, b) => b.blockNumber - a.blockNumber);
            console.log("SwapHistory", sortedSwaps);
            setSwapHistory(sortedSwaps);
        } catch (error) {
            console.error('Error fetching swap history:', error);
            setStatus(`Error fetching swap history: ${(error as Error).message}`);
        }
    };

    const fetchLogs = async () => {
        try {
            const response = await axios.get<Log[]>(`http://${process.env.REACT_APP_API_ADDRESS}/logs`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setLogs(response.data);
        } catch (error) {
            console.error('Error fetching logs:', error);
        }
    };

    const fetchBalances = async () => {
        try {
            const response = await axios.get<Balances>(`http://${process.env.REACT_APP_API_ADDRESS}/balances`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setBalances(response.data);
        } catch (error) {
            console.error('Error fetching balances:', error);
        }
    };

    const fetchStatus = async () => {
        try {
            const response = await axios.get<Status>(`http://${process.env.REACT_APP_API_ADDRESS}/statusIOL`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setBotStatus(response.data);
        } catch (error) {
            console.error('Error fetching status:', error);
        }
    };

    const handleClearLogs = () => {
        setShowConfirmDialog(true);
    };

    const confirmClearLogs = async () => {
        try {
            await axios.post(
                `http://${process.env.REACT_APP_API_ADDRESS}/clear-logs`,
                {},
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                }
            );
            setLogs([]);
            setStatus('Logs cleared successfully');
        } catch (error) {
            console.error('Error clearing logs:', error);
            setStatus('Error clearing logs');
        } finally {
            setShowConfirmDialog(false);
        }
    };

    if (!isLoggedIn) {
        return <Login />;
    }

    return (
        <div className="App">
            <div className="header-container">
                <Link to="/" className="back-button">← back</Link>
                <div className="title-container">
                    <h1><span className="iol-text">IOL</span> Bot Control Panel</h1>
                    <span className={`bot-status ${botStatus.isRunning ? 'running' : 'stopped'}`}>
                        {botStatus.isRunning ? 'bot is running' : 'bot stopped'}
                    </span>
                </div>
            </div>
            <button onClick={logout}>Logout</button>
            <div className="control-buttons">
                <button onClick={() => controlBot('start')}>Start Bot</button>
                <button onClick={() => controlBot('stop')}>Stop Bot</button>
            </div>
            <p className="status">{status}</p>
            <div className="balances-container">
                {balances && (
                    <div className="balances-grid">
                        <div className="balance-item">
                            <p>wIOTA Balance: {balances.wIOTA}</p>
                        </div>
                        <div className="balance-item">
                            <p>IOL Balance: {balances.IOL}</p>
                        </div>
                        <div className="balance-item">
                            <p>USDT Balance: {balances.USDT}</p>
                        </div>
                        <div className="balance-item">
                            <p>Native Balance: {balances.native}</p>
                        </div>
                    </div>
                )}
            </div>
            <div className="balances-container">
                {balances && (
                    <div className="balances-grid">
                        <div className="balance-item">
                            <p>treasuryUSDTbalance: {balances.treasuryUSDTbalance}</p>
                        </div>
                    </div>
                )}
            </div>
            <div className="settings-container">
                <div className="current-settings">
                    <h2>Current Settings</h2>
                    {settings && (
                        <pre>
                            {`Max Price Impact wIOTA USDT: ${settings.max_price_impact_wIOTA_USDT / 100}%
Slippage wIOTA USDT: ${settings.slippage_wIOTA_USDT / 100}%
Slippage wIOTA IOL: ${settings.slippage_wIOTA_IOL / 100}%
Value of Growth to Sell: ${settings.value_of_growth_to_sell[0] / 100}% - ${settings.value_of_growth_to_sell[1] / 100}%
IOL Price Threshold: ${settings.IOL_price_threshold_percent[0] / 100}% - ${settings.IOL_price_threshold_percent[1] / 100}%
minIOL price in USDT: ${ethers.formatEther(settings.minIOLprice)}
Custom RPC URL: ${settings.customRPC || 'Using default RPC'}`}
                        </pre>
                    )}
                </div>

                <div className="update-settings">
                    <h2>Update Settings</h2>
                    <form onSubmit={updateSettings}>
                        <label>Max Price Impact wIOTA USDT (%): <input type="number" name="max_price_impact_wIOTA_USDT" defaultValue={settings?.max_price_impact_wIOTA_USDT} required /></label>
                        <label>Slippage wIOTA USDT (%): <input type="number" name="slippage_wIOTA_USDT" defaultValue={settings?.slippage_wIOTA_USDT} required /></label>
                        <label>Slippage wIOTA IOL (%): <input type="number" name="slippage_wIOTA_IOL" defaultValue={settings?.slippage_wIOTA_IOL} required /></label>
                        <label>Value of Growth to Sell (Min %): <input type="number" name="value_of_growth_to_sell_min" defaultValue={settings?.value_of_growth_to_sell[0]} required /></label>
                        <label>Value of Growth to Sell (Max %): <input type="number" name="value_of_growth_to_sell_max" defaultValue={settings?.value_of_growth_to_sell[1]} required /></label>
                        <label>IOL Price Threshold (Min %): <input type="number" name="IOL_price_threshold_percent_min" defaultValue={settings?.IOL_price_threshold_percent[0]} required /></label>
                        <label>IOL Price Threshold (Max %): <input type="number" name="IOL_price_threshold_percent_max" defaultValue={settings?.IOL_price_threshold_percent[1]} required /></label>
                        <label>min IOL price in USDT: <input
                            type="number"
                            name="minIOLpriceInUSDT"
                            defaultValue={settings ? ethers.formatEther(settings.minIOLprice) : ''}
                            step="0.000000000000000001"
                            required
                        /></label>
                        <label>Custom RPC URL: <input
                            type="text"
                            name="customRPC"
                            defaultValue={settings?.customRPC}
                            placeholder="https://your-rpc-url.com"
                        /></label>
                        <button type="submit">Update Settings</button>
                    </form>
                </div>
            </div>

            <div className="swap-history-container">
                <div className="logs-header">
                    <div onClick={() => setIsSwapsVisible(!isSwapsVisible)}>
                        <h2>Recent Swaps</h2>
                        <span>{isSwapsVisible ? '▼' : '▶'}</span>
                    </div>
                </div>
                {isSwapsVisible && (
                    <div className="swap-list">
                        {swapHistory.map((swap, index) => (
                            <div key={index} className="swap-item">
                                <p>Block: {swap.blockNumber}</p>
                                <p>Price Bias: {Number(swap.percentPriceBias) / 100}%</p>
                                <p>Bias Swapped: {Number(swap.percentOfBiasToSwap) / 100}%</p>
                                <p>IOL Swapped: {Number(ethers.formatEther(swap.amountIOLswapped)).toFixed(6)}</p>
                                <p>IOTA Swapped: {Number(ethers.formatEther(swap.amountIOTAswapped)).toFixed(6)}</p>
                                <p>USDT Received: {Number(ethers.formatUnits(swap.usdtReceived, 6)).toFixed(6)}</p>
                                <p>{new Date(swap.timestamp).toLocaleString()}</p>
                                <div className="tx-links">
                                    <a href={`https://explorer.evm.iota.org/tx/${swap.txIOLsell}`} target="_blank" rel="noopener noreferrer">IOL Tx</a>
                                    <a href={`https://explorer.evm.iota.org/tx/${swap.txIOTAsell}`} target="_blank" rel="noopener noreferrer">IOTA Tx</a>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className="logs-container">
                <div className="logs-header">
                    <div onClick={() => setIsLogsVisible(!isLogsVisible)}>
                        <h2>Bot Logs</h2>
                        <span>{isLogsVisible ? '▼' : '▶'}</span>
                    </div>
                    <button onClick={handleClearLogs} className="clear-logs-btn">Delete Logs</button>
                </div>
                {isLogsVisible && (
                    <div className="logs-content">
                        {logs.map((log, index) => (
                            <div key={index} className="log-item">
                                <span className="log-time">{new Date(log.timestamp).toLocaleString()}</span>
                                <span className="log-message">{log.message}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {showConfirmDialog && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h3>Confirm Delete</h3>
                        <p>Are you sure you want to delete all logs?</p>
                        <div className="modal-buttons">
                            <button className="modal-cancel" onClick={() => setShowConfirmDialog(false)}>Cancel</button>
                            <button className="modal-confirm" onClick={confirmClearLogs}>Delete</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default IOL;


