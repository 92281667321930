import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Login from '../components/Login';

const Home = () => {
    const { isLoggedIn } = useAuth();

    if (!isLoggedIn) {
        return <Login />;
    }

    return (
        <div className="home-container">
            <Link to="/iol">
                <button className="iol-button">Go to IOL</button>
            </Link>
            <Link to="/cyb">
                <button className="cyb-button">Go to CYB</button>
            </Link>
        </div>
    );
};

export default Home;

