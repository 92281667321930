import { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

const Login = () => {
    const { setIsLoggedIn } = useAuth();
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [status, setStatus] = useState<string>('');

    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const response = await axios.post(`http://${process.env.REACT_APP_API_ADDRESS}/login`, {
                username,
                password
            });
            const newToken = response.data.token;
            localStorage.setItem('token', newToken);
            setIsLoggedIn(true);
        } catch (error) {
            console.error('Login error:', error);
            if (axios.isAxiosError(error) && error.response) {
                setStatus(`Login error: ${error.response.data.error}. ${error.response.data.details || ''}`);
            } else {
                setStatus(`Login error: ${(error as Error).message}`);
            }
        }
    };

    return (
        <div className="App">
            <h1>Login</h1>
            <form onSubmit={handleLogin}>
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button type="submit">Login</button>
            </form>
            <p className="status">{status}</p>
        </div>
    );
};

export default Login;
